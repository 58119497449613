import React, { useState, useEffect } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [dark, setDark] = useState(undefined);

  useEffect(() => {
    setDark(isDark());
  }, []);

  const isDark = () => {
    const gatsbyDiv = document.getElementById("___gatsby");

    if (gatsbyDiv.classList.contains("dark")) {
      return true;
    }

    return false;
  };

  const switchTheme = () => {
    const gatsbyDiv = document.getElementById("___gatsby");

    gatsbyDiv.classList.toggle("light");
    gatsbyDiv.classList.toggle("dark");

    if (gatsbyDiv.classList.contains("dark")) {
      setDark(true);
      localStorage.setItem("dark", true);
    } else {
      setDark(false);
      localStorage.setItem("dark", false);
    }
  };

  return (
    <header>
      <div
        className={`flex flex-wrap items-center justify-between px-4 pt-4 mx-auto`}
      >
        <AniLink fade to="/">
          <h1 className="flex items-center no-underline p-1 bg-white rounded-full">
            <img
              alt="Profile Pic"
              className="w-24 h-24 rounded-full"
              src={require("../images/profile.png")}
            />
          </h1>
        </AniLink>

        <button
          className="flex items-center block px-3 py-2 border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } text-center bg-background-3 rounded-lg mt-2 py-2 md:px-4 md:block md:flex md:items-center w-full md:w-auto`}
        >
          {
            <React.Fragment>
              <button
                onClick={switchTheme}
                className="cursor-pointer text-xl focus:outline-none"
              >
                {dark === true ? (
                  <FontAwesomeIcon icon={faSun} className="text-yellow-400" />
                ) : (
                  <FontAwesomeIcon icon={faMoon} className="text-blue-600" />
                )}
              </button>
              {[
                {
                  route: `/`,
                  title: `Me`,
                },
                {
                  route: `/projects`,
                  title: `Projects`,
                },
                // {
                //   route: `/blog`,
                //   title: `Blog`,
                // },
              ].map((link) => (
                <AniLink
                  fade
                  className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 hover:text-black hover:underline"
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </AniLink>
              ))}
            </React.Fragment>
          }
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
