import PropTypes from "prop-types";
import React, { useContext } from "react";
import Header from "./header";
import ThemeContext from "../context/themeContext";

function Layout({ children }) {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      id="background"
      className={`flex flex-col font-mono text-copy-primary box-border main-content-bg min-h-screen`}
    >
      <div
        id="container"
        className="flex flex-col bg-background-primary m-2 p-1 rounded-lg"
      >
        <Header
          onSwitchTheme={() => console.log("switch theme")}
          theme={themeContext}
        />

        <main className="flex-1 w-full px-4 py-4 mx-auto box-border">
          {children}
        </main>

        <footer className="p-3">
          <div className="text-lg">
            <span className="text-xl">©</span> Copyright 2018 -{" "}
            {new Date().getFullYear()}. Marcos Comelli.
          </div>
        </footer>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
